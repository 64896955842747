interface YesNo {
	allowing_wine_dtc_shipping: string;
	allowing_spirits_dtc_shipping: {
		label: string;
		value: string;
	};
	spirits_dtc_text: string;
}

export interface AcfInterface {
	state_gdp: string;
	state_export_data: string;
	jobs_supported: string;
	yes__no: YesNo;
	state_id: StateDistrict;
}

export type StateLookup = {
	state_gdp: string;
	state_export_data: string;
	jobs_supported: string;
	yes__no: YesNo;
	state_id: StateDistrict;
};

export type StateDistrict = typeof stateDistrict[number];

const stateDistrict = [
	`AK`,
	`AL`,
	`AR`,
	`AZ`,
	`CA`,
	`CO`,
	`CT`,
	`DC`,
	`DE`,
	`FL`,
	`GA`,
	`HI`,
	`IA`,
	`ID`,
	`IL`,
	`IN`,
	`KS`,
	`KY`,
	`LA`,
	`MA`,
	`MD`,
	`ME`,
	`MI`,
	`MN`,
	`MO`,
	`MS`,
	`MT`,
	`NC`,
	`ND`,
	`NE`,
	`NH`,
	`NJ`,
	`NM`,
	`NV`,
	`NY`,
	`OH`,
	`OK`,
	`OR`,
	`PA`,
	`RI`,
	`SC`,
	`SD`,
	`TN`,
	`TX`,
	`UT`,
	`VA`,
	`VT`,
	`WA`,
	`WI`,
	`WV`,
	`WY`,
] as const;

export const isStateDistrict = (x: any): x is StateDistrict =>
	stateDistrict.includes(x);

export const stateNames: {
	[key in StateDistrict]: string;
} = {
	AL: `Alabama`,
	AK: `Alaska`,
	AZ: `Arizona`,
	AR: `Arkansas`,
	CA: `California`,
	CO: `Colorado`,
	CT: `Connecticut`,
	DE: `Delaware`,
	DC: `District of Columbia`,
	FL: `Florida`,
	GA: `Georgia`,
	HI: `Hawaii`,
	ID: `Idaho`,
	IL: `Illinois`,
	IN: `Indiana`,
	IA: `Iowa`,
	KS: `Kansas`,
	KY: `Kentucky`,
	LA: `Louisiana`,
	ME: `Maine`,
	MD: `Maryland`,
	MA: `Massachusetts`,
	MI: `Michigan`,
	MN: `Minnesota`,
	MS: `Mississippi`,
	MO: `Missouri`,
	MT: `Montana`,
	NE: `Nebraska`,
	NV: `Nevada`,
	NH: `New Hampshire`,
	NJ: `New Jersey`,
	NM: `New Mexico`,
	NY: `New York`,
	NC: `North Carolina`,
	ND: `North Dakota`,
	OH: `Ohio`,
	OK: `Oklahoma`,
	OR: `Oregon`,
	PA: `Pennsylvania`,
	RI: `Rhode Island`,
	SC: `South Carolina`,
	SD: `South Dakota`,
	TN: `Tennessee`,
	TX: `Texas`,
	UT: `Utah`,
	VT: `Vermont`,
	VA: `Virginia`,
	WA: `Washington`,
	WV: `West Virginia`,
	WI: `Wisconsin`,
	WY: `Wyoming`,
};
