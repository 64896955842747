/* eslint-disable react/no-unknown-property */
import tw from "twin.macro";
import {
	PortableText,
	PortableTextComponents,
} from "@portabletext/react";
import {
	ImLinkedin,
	ImFacebook2,
	ImTwitter,
} from "react-icons/im";
import { SiInstagram } from "react-icons/si";

import { StateDistrict } from "../../util/data/states";
import type { Campaign } from "../../templates/state";

//! ----------> STYLES <----------
const baseStyles = tw`font-display text-light-beige`;

const iconStyle = tw`transition duration-500 ease-in-out text-xl hover:(opacity-70)`;

//! ----------> HELPERS <----------
const blockComponents: PortableTextComponents = {
	marks: {
		strong: ({ children }) => (
			<span tw="font-bold">{children}</span>
		),
		em: ({ children }) => <span tw="italic">{children}</span>,
	},
	block: {
		h2: ({ children }) => (
			<h2
				css={[
					baseStyles,
					tw`text-2xl leading-[26px] font-bold mb-4`,
				]}
			>
				{children}
			</h2>
		),
		h3: ({ children }) => (
			<h4
				css={[
					baseStyles,
					tw`font-medium font-alternate text-[2rem] leading-[2.5rem] mb-8`,
				]}
			>
				{children}
			</h4>
		),
		normal: ({ children }) => (
			<p css={[baseStyles]} tw="text-lg">
				{children}
			</p>
		),
	},
	list: {
		bullet: ({ children }) => (
			<ul
				css={[
					baseStyles,
					`li { ${tw`list-disc list-outside text-lg`}; }`,
				]}
				tw="list-disc list-outside ml-6 mb-5 text-lg"
			>
				{children}
			</ul>
		),
	},
};

//! ----------> COMPONENTS <----------
export const Socials = () => (
	<div tw="antialiased text-current font-bold font-display flex space-x-5 pt-14">
		<p>Share This</p>
		<a
			css={[iconStyle]}
			href="https://www.facebook.com/ShipMySpirits"
			target="_blank"
			rel="noreferrer"
		>
			<ImFacebook2 />
		</a>

		<a
			css={[iconStyle]}
			href="https://www.instagram.com/shipmyspirits/?hl=en"
			target="_blank"
			rel="noreferrer"
		>
			<SiInstagram />
		</a>

		<a
			css={[iconStyle]}
			href="https://twitter.com/ShipMySpirits/"
			target="_blank"
			rel="noreferrer"
		>
			<ImTwitter />
		</a>

		<a
			css={[iconStyle]}
			href="https://www.linkedin.com/showcase/ship-my-spirits"
			target="_blank"
			rel="noreferrer"
		>
			<ImLinkedin />
		</a>
	</div>
);

const CampaignDetails = ({
	abbrv,
	state,
}: {
	abbrv: StateDistrict;
	state?: Campaign;
}) => {
	return (
		<div tw="w-full pb-14 px-8 md:(px-12) lg:(px-0)">
			{state ? (
				<PortableText
					value={state._rawDetails}
					components={blockComponents}
				/>
			) : (
				<div css={[baseStyles]}>
					<h3 tw="text-[2rem] leading-[2.5rem] mb-8 font-alternate">
						Looks like we don&apos;t have a state-based
						campaign in your state yet, BUT YOU CAN STILL
						HELP!
					</h3>

					<h2 tw="text-2xl leading-[26px] font-semibold mb-8">
						Ask Congress to allow the USPS to ship distilled
						spirits!
					</h2>

					<p tw="mb-5 text-lg">
						Did you know the United States Postal Service
						(USPS) can&apos;t ship distilled spirits or other
						beverage alcohol even though other carriers are
						allowed to do so in states where permitted?
					</p>

					<p tw="mb-5 text-lg">
						Spirits consumers deserve the right to access
						their favorite distilled spirits products safely
						and conveniently. As wine producers have
						demonstrated for decades, direct-to-consumer
						shipping can be done safely and securely.
					</p>

					<p tw="mb-5 font-medium text-lg">
						Urge your members of Congress to cosponsor the
						USPS Shipping Equity Act now to enable the USPS to
						ship beverage alcohol!
					</p>

					<p tw=" text-lg">
						By providing another option for direct-to-consumer
						shipping, this legislation:
					</p>

					<ul
						tw="list-disc list-outside mb-5 ml-6  text-lg"
						css={[`li { ${tw`list-outside`}; }`]}
					>
						<li>Supports consumer choice,</li>
						<li>
							Supports businesses that have suffered during
							the pandemic,
						</li>
						<li>
							Enables the USPS to participate in the modern
							marketplace and generate new revenue.
						</li>
					</ul>

					<p tw="font-medium  text-lg">
						Ask your members of Congress to cosponsor the USPS
						Shipping Equity Act today!
					</p>
				</div>
			)}

			<div tw="hidden text-light-beige lg:(block)">
				<Socials />
			</div>
		</div>
	);
};

export default CampaignDetails;
