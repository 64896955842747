/* eslint-disable react/no-unknown-property */
import React from "react";
import tw, { styled } from "twin.macro";

import { Socials } from "./details";

const Wrapper = styled.div`
	${tw`w-full h-[fit-content]`};
	${tw`bg-light-beige`};
	${tw`px-8 md:(px-12) xl:(px-16)`};
	${tw`pt-4 pb-12 md:(pt-8 pb-14)`};
`;

const Container = styled.div`
	select {
		${tw`w-full bg-oat text-black`};
		${tw`uppercase`};
		${tw`px-1.5 py-1.5 leading-[24px]`};
	}

	form > dl {
		${tw`flex flex-col space-y-2.5`};
	}

	dt {
		height: 0px;
	}

	dt > label,
	.wsd-input-content > dl > dt {
		visibility: hidden;
	}

	input[type="text"],
	input[type="email"] {
		${tw`w-full!`};
		${tw`bg-oat uppercase text-black`};
		${tw`font-display px-3 py-1.5 leading-[24px]`};
		${tw`placeholder:(text-black)`};
	}

	input[type="submit"] {
		${tw`bg-orange text-white uppercase`};
		${tw`font-display font-semibold text-base`};
		${tw`w-full h-[39px] flex items-center justify-center`};
		${tw`md:(w-[14.0625rem])`};
		${tw`rounded-md pt-1`};
	}

	textarea {
		resize: none;
		${tw`mt-4 p-3`};
		${tw`w-full bg-oat text-black`};
		${tw`font-display`};
		${tw`h-[12.0625rem]`};
	}

	.wsd_remember_me {
		${tw`font-display my-6`};
	}
`;

const CampaignForm = () => {
	return (
		<>
			<Wrapper>
				<h2 tw="text-4xl text-dark-teal font-sticker mb-6">
					ACT NOW!
				</h2>
				<Container id="wsd-root" className="spkactionform" />
				<div tw="text-dark-teal lg:(hidden)">
					<Socials />
				</div>
			</Wrapper>
		</>
	);
};

export default CampaignForm;
