/* eslint-disable react/no-unknown-property */
import * as React from "react";
import { useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import tw, { styled } from "twin.macro";

import SEO from "../components/util/SEO";
import Layout from "../components/util/Layout";
import NavLayout from "../components/layout/nav-layout";
import CampaignForm from "../components/take-action/form-embed";
import CampaignDetails from "../components/take-action/details";
import {
	StateDistrict,
	stateNames,
} from "../util/data/states";

import factsBG from "../assets/images/facts-map.png";

//! ----------> TYPES <----------
export type Campaign = {
	_rawDetails: any[];
	embedLink: string;
	name: StateDistrict;
	restricted: boolean;
	type: string;
};

type CampaignData = {
	sanityCampaign: Campaign;
};

//! ----------> STYLES <----------
const Wrapper = styled.div`
	${tw`w-screen min-h-screen bg-bourbon`};
	${tw`antialiased`};
	${tw`pt-[5.8125rem] lg:(pt-[12.75rem])`};
	${tw`lg:(pb-20)`};
	background-image: url("${factsBG}");
	background-size: 98%;
	background-position: center 4%;
	background-repeat: no-repeat;

	@media (min-width: 768px) {
		background-size: 78%;
		background-position: 3% 5%;
	}

	@media (min-width: 1024px) {
		background-size: 72%;
		background-position: 3% 18%;
	}

	@media (min-width: 1280px) {
		background-size: 60%;
		background-position: 112% 8%;
	}
`;

const Container = styled.div`
	${tw`w-full h-[fit-content] lg:(px-16) xl:(px-28) 2xl:(px-0 max-w-[81.9375rem] mx-auto)`};
`;

const Link = tw.a`flex text-light-beige font-display uppercase font-semibold items-center`;

//! ----------> COMPONENTS <----------
const Arrow = () => (
	<svg
		width="34"
		height="14"
		viewBox="0 0 34 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M0 7L33 7" stroke="#F9F4ED" />
		<path d="M26 1L33 7L26 13" stroke="#F9F4ED" />
	</svg>
);

const StatePage = ({
	data,
	pageContext,
}: {
	data: CampaignData;
	pageContext: Record<string, string>;
}) => {
	const campaign = data?.sanityCampaign;
	const stateName =
		stateNames[pageContext.stateName as StateDistrict];

	useEffect(() => {
		if (typeof window !== `undefined`) {
			window.document.body.style.overflow = `unset`;
		}
	}, []);

	return (
		<Layout>
			<Helmet>
				<script
					src={
						campaign
							? campaign.embedLink
							: `https://www.spiritsunited.org/assets/js/widget.js/?id=6529`
					}
					async
					type="text/javascript"
				/>
			</Helmet>
			<SEO />
			<NavLayout activePage="state-facts" colorTheme="light">
				<Wrapper>
					<Container>
						<div tw="mb-12 px-8 md:(mb-14 px-12) lg:(px-0) xl:(mb-16)">
							{pageContext.stateName === `USA` ? (
								<>
									<h1 tw="text-light-beige text-4xl font-sticker mb-2.5">
										United States
									</h1>
									<Link href="/state-facts">
										<p>Get the facts about U.S. states</p>
										{` `}
										<span tw="ml-2 mb-1">
											<Arrow />
										</span>
									</Link>
								</>
							) : (
								<>
									<h1 tw="text-light-beige text-4xl font-sticker mb-2.5">
										{stateName}
									</h1>
									<Link
										href={`/state-facts?state=${pageContext.stateName}`}
									>
										<p>Get the facts about your state</p>
										{` `}
										<span tw="ml-2 mb-1">
											<Arrow />
										</span>
									</Link>
								</>
							)}
						</div>
						<div tw="lg:(flex space-x-12) xl:(space-x-16)">
							<CampaignDetails
								state={campaign}
								abbrv={pageContext.stateName as StateDistrict}
							/>
							<CampaignForm />
						</div>
					</Container>
				</Wrapper>
			</NavLayout>
		</Layout>
	);
};

export const query = graphql`
	query ($stateName: String!) {
		sanityCampaign(name: { eq: $stateName }) {
			name
			type
			restricted
			_rawDetails
			embedLink
		}
	}
`;

export default StatePage;
